/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Popper, PopperProps } from '@mui/material';
import _ from 'lodash';
import { useRef, useContext } from 'react';

import CustomAutocomplete from '@/components/core/common/autocomplete/custom-autocomplete';
import { useCommunityContext } from '@/contexts/community-context';
import { useMenuContext } from '@/contexts/menu-context';
import { useUserCommunitiesContext } from '@/contexts/user-communities-context';
import { CommunityInterface } from '@/models/entities/community-interface';
import { WeverCoreContext, _translate } from '@/services/core';
import router from '@/services/core/router';

import { communitiesSelectStyle, communityPopperStyle } from './communities-select.style';

type CoreType = {
  locale: string;
};

const UserCommunities = ({ sidebarOpen }: { sidebarOpen: boolean }) => {
  const { setUserCommunity, communityData } = useCommunityContext();
  const { userCommunities, userCommunitiesLoading } = useUserCommunitiesContext();
  const { setJourneyTypeId } = useMenuContext();

  const { locale }: CoreType = useContext(WeverCoreContext);

  const divRef = useRef<HTMLDivElement>();

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: CommunityInterface | null,
  ) => {
    if (newValue && newValue.id !== communityData?.id) {
      setUserCommunity(`/communities/${newValue?.id}`);
      setJourneyTypeId(0);
      router.navigate('/', null);
    }
  };

  const CustomPopper = (props: PopperProps) => {
    const anchorEl = divRef.current;
    return (
      <Popper
        className='community-popper'
        css={communityPopperStyle(sidebarOpen)}
        {...props}
        anchorEl={anchorEl}
        placement='bottom-end'
      />
    );
  };

  const formatGroupName = (groupName: string) => {
    const last = groupName[groupName.length - 1];

    return `${groupName}${last !== 's' && '(s)'}`;
  };

  const dataOrdered = _.orderBy(userCommunities, ['structureType.' + locale, 'name'], ['asc']);

  return (
    <div css={communitiesSelectStyle(sidebarOpen)}>
      <CustomAutocomplete<CommunityInterface>
        componentRef={divRef}
        handleChange={handleChange}
        CustomPopper={CustomPopper}
        data={dataOrdered}
        groupBy={(option) => (option.structureType ? option.structureType[locale] : null)}
        defaultValue={communityData}
        getOptionLabel={(option) => option.name ?? ''}
        isLoading={userCommunitiesLoading}
        loadingIconSize={15}
        startElement={<VisibilityIcon className='eye-icon' />}
        renderOption={(props, option) => (
          <li style={{ fontSize: '14px' }} {...props}>
            {option.name}
          </li>
        )}
        renderGroup={(params) => {
          const childs = params.children;
          return (
            <li>
              <div className='community-select-group-header'>
                {params.group
                  ? formatGroupName(params.group)
                  : _translate('manager_app_other_group')}{' '}
                ({Array.isArray(childs) ? childs.length : 0})
              </div>
              <div className='community-select-group-body'>{childs}</div>
            </li>
          );
        }}
        size='small'
      />
    </div>
  );
};

export default UserCommunities;
