import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { structureListTabs } from '@/views/structure-list/helpers';

const useStructureList = () => {
  const location = useLocation<Location>();
  const [activeTab, setActiveTab] = useState<number | undefined>(0);

  const urlParams = new URLSearchParams(location.search);
  const [status, setStatus] = useState<string>(structureListTabs[urlParams.get('tab') ?? 0].status);

  return { activeTab, setActiveTab, status, setStatus };
};

export default useStructureList;
