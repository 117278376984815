import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import LoadingLayout from '@/components/layout/loading-layout';
import AdminNavbar from '@/components/layout/navbars/admin-navbar';
import UserSidebar from '@/components/layout/sidebar/user-sidebar';
import { useCommunityContext } from '@/contexts/community-context';
import { useUserContext } from '@/contexts/user-context';

// import Banner from '@/components/core/common/banner/banner';
// import { containsNumbers } from '@/components/core/common/helpers';

export interface AdminProps {
  routes: () => void;
}

const Admin = ({ routes }: AdminProps) => {
  const [sidebarOpen, setSideBarOpen] = useState(true);
  const { communityLoading } = useCommunityContext();
  const { userLoading } = useUserContext();

  const { pathname } = useLocation<Location>();
  const history = useHistory<History>();

  const currentPathnameRef = useRef<string | null>(null);
  const currentSearchRef = useRef<string | null>(null);

  useEffect(() => {
    const handleLocationChange = (newLocation: any | string, action: string) => {
      if (action === 'PUSH') {
        if (
          (typeof newLocation === 'string' && newLocation !== currentPathnameRef.current) ||
          (typeof newLocation === 'object' &&
            (newLocation.pathname !== currentPathnameRef.current ||
              newLocation.search !== currentSearchRef.current))
        ) {
          // Save new location
          currentPathnameRef.current =
            typeof newLocation === 'string' ? newLocation : newLocation.pathname;
          currentSearchRef.current = typeof newLocation === 'object' ? newLocation.search : '';

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
          });
        }
      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    };

    const unlisten = history.listen(handleLocationChange);

    return () => {
      unlisten(); // Cleanup the listener when the component unmounts
    };
  }, [history]);

  // Scroll top when user navigates between different pages
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (userLoading || communityLoading) {
    return <LoadingLayout />;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <UserSidebar sidebarOpen={sidebarOpen} setSideBarOpen={setSideBarOpen} />
      </div>
      {/*@ts-ignore*/}
      <div style={{ width: '100%', paddingTop: '75px' }}>
        <AdminNavbar />
        {/* {!containsNumbers(pathname) && <Banner />} */}
        {routes()}
      </div>
    </div>
  );
};

export default Admin;
