import { css } from '@emotion/react';

import { DataPrivate } from '@/models/entities/common-types';
import { MenuItemsInterface } from '@/models/entities/menu-interface';

export const userSidebarMenuStyle = (
  item: MenuItemsInterface | undefined,
  open: boolean,
  isSelected: boolean,
  dataPrivate?: DataPrivate,
) => css`
  cursor: pointer;
  hr {
    margin-left: 30px;
    margin-right: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .list-item {
    display: block;
    height: 40px;

    .rectangle-icon {
      background-color: ${!isSelected ? '#183d4c' : dataPrivate?.primaryColor?.value ?? '#00aad7'};
      width: 5px;
      height: 20px;
      position: absolute;
      left: 0px;
    }

    .list-button {
      background-color: transparent;
      min-height: 48px;
      justify-content: ${open ? 'initial' : 'center'};
      padding-left: 20px;
      padding-right: 20px;

      .list-item-icon {
        min-width: 0px;
        margin-right: ${open ? '15px' : 'auto'};
        justify-content: center;
        width: ${open ? 'initial' : '100%'};

        .svg-component {
          fill: ${!isSelected ? '#183d4c' : dataPrivate?.primaryColor?.value ?? '#00aad7'};
          width: 20px;
        }
      }
      .list-item-text {
        span {
          font-size: ${item?.size ? `${item?.size}px` : '18px'};
          font-family: 'Roboto', sans-serif;
          font-weight: ${item?.width ? item?.width : 'bold'};
          color: ${!isSelected ? '#183d4c' : dataPrivate?.primaryColor?.value ?? '#00aad7'};
          text-transform: ${item?.height ? item?.height : 'none'};
          opacity: ${open ? 1 : 0};
        }
      }
    }
  }
`;

export const userSidebarMenuSubContent = (
  item: MenuItemsInterface | undefined,
  open: boolean,
) => css`
    padding-left: 35px;
    padding-top: 8px;
    .list-item-text {
      span {
        font-size: ${item?.size ? `${item?.size}px` : '18px'};
        font-family: 'Roboto', sans-serif;
        font-weight: ${item?.width ? item?.width : 'bold'};
        color: #183d4c;
        text-transform: ${item?.height ? item?.height : 'none'};
        opacity: ${open ? 1 : 0};
      }
    }
  }
`;
