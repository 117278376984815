/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import 'wever-dashboard/dist/styles/bootstrap.css';

import CustomAutocomplete from '@/components/core/common/autocomplete/custom-autocomplete';
import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import { RoutesPath } from '@/components/core/common/helpers';
import translate from '@/components/core/common/translate';
import LoadingLayout from '@/components/layout/loading-layout';
import { useCommunityContext } from '@/contexts/community-context';
import { useMenuContext } from '@/contexts/menu-context';
import useGraphsByTags from '@/hooks/use-graphs-by-tags';
import useWindowSize from '@/hooks/use-window-size';
import { GraphInterface } from '@/models/entities/graph-interface';
import {
  _WeverDashboard as WeverDashboard,
  _Security as security,
  _translate as t,
  WeverCoreContext as Context,
} from '@/services/core';
import router from '@/services/core/router';
import { graphStyle } from '@/views/graphs/single-graph/graph.style';
import useSingleGraph from '@/views/graphs/single-graph/use-single-graph';

const Graph = () => {
  const { communityData } = useCommunityContext();
  const { currentJourneyType, menuConfiguration } = useMenuContext();
  const { data, isLoading: dataLoading } = useGraphsByTags();
  const { locale } = useContext<Record<string, string>>(Context);
  const { isLoading, singleGraph, reportId, groupedDataByJourneyType } = useSingleGraph(
    data,
    currentJourneyType,
    locale,
    communityData,
  );
  const location = useLocation<Location>();
  const { windowSize } = useWindowSize();

  const isMapView = location.pathname === `/${RoutesPath.Maps}/${singleGraph?.id}` ? true : false;

  const breadCrumbsItems = [
    {
      name: t(menuConfiguration?.menu[0].title),
      route: menuConfiguration?.menu[0].isDynamicHomePage ? RoutesPath.Init : undefined,
    },
    {
      name: !isMapView ? t('manager_app_graphs') : t('manager_app_maps'),
      route: !isMapView ? RoutesPath.Graphs : RoutesPath.Maps,
    },
    {
      name: translate(currentJourneyType?.name),
      route: `${!isMapView ? RoutesPath.Graphs : RoutesPath.Maps}?tab=${currentJourneyType?.id}`,
    },
    { name: translate(singleGraph?.name), route: undefined },
  ];

  if (isLoading) {
    return <LoadingLayout />;
  }

  if (!reportId) {
    return <div className='w-100 text-center'>{t('adminapp_no_resource_for_this_community')}</div>;
  }

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: GraphInterface | null,
  ) => {
    router.navigate(`${newValue?.id}`, null);
  };

  return (
    <div css={graphStyle(isMapView, windowSize.innerHeight)}>
      <div className='graph-breadcrumbs'>
        <Breadcrumbs items={breadCrumbsItems} />
      </div>
      <div className='graph-select'>
        <CustomAutocomplete<GraphInterface>
          handleChange={handleChange}
          data={groupedDataByJourneyType}
          defaultValue={singleGraph}
          getOptionLabel={(option) => option.name[locale] ?? ''}
          groupBy={(option) => (option.journeyType ? option.journeyType : null)}
          isLoading={dataLoading}
          loadingIconSize={15}
          renderOption={(props, option) => (
            <li style={{ fontSize: '16px' }} {...props}>
              {translate(option.name)}
            </li>
          )}
          size='small'
        />
      </div>
      <div className='graph graph-dashboard-container'>
        <WeverDashboard
          editionMode
          dashboard={singleGraph?.id}
          locale={locale}
          token={security.token}
          report={reportId}
          community={communityData?.id}
        />
      </div>
    </div>
  );
};

export default Graph;
