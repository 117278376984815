import { Fragment } from 'react';
import React from 'react';

import { RoutesPath } from '@/components/core/common/helpers';
import { useMenuContext } from '@/contexts/menu-context';
import useActionsSheetsByTags from '@/hooks/use-actions-sheets-by-tags';
import { ActionsSheetInterface } from '@/models/entities/actions-sheets-interface';
import { _translate as t } from '@/services/core';
import Dashboard from '@/views/dashboard/dashboard';

const ActionSheets = () => {
  const { journeyTypeId, setJourneyTypeId } = useMenuContext();
  const { data, isLoading } = useActionsSheetsByTags();

  return (
    <Fragment>
      <Dashboard<ActionsSheetInterface>
        data={data}
        setJourneyTypeId={setJourneyTypeId}
        isLoading={isLoading}
        subtitle={t('manager_app_see_action_sheet')}
        path={RoutesPath.ActionSheets}
        journeyTypeId={journeyTypeId}
        title={t('manager_app_action_sheets')}
      />
    </Fragment>
  );
};

export default ActionSheets;
