import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getUniqueListBy } from '@/components/core/common/helpers';
import { ActionsSheetInterface } from '@/models/entities/actions-sheets-interface';
import { JourneyType, NameTrait } from '@/models/entities/common-types';
import { _Api } from '@/services/core';

const useSingleActionSheet = (
  data: ActionsSheetInterface[] | undefined,
  currentJourneyType: JourneyType,
  locale: string,
) => {
  const [actionSheet, setActionSheet] = useState<ActionsSheetInterface | undefined>(undefined);
  const [activeTab, setActiveTab] = useState<number | undefined>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();

  const getActionSheet = useCallback(async () => {
    setIsLoading(true);
    const response: AxiosResponse<ActionsSheetInterface> = await _Api.get(`/sheet_actions/${id}`);

    if (response) {
      setActionSheet(response.data);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    getActionSheet();
  }, [getActionSheet]);

  const workingFieldCategories = getUniqueListBy<{ names: NameTrait | undefined; alias: string }>(
    currentJourneyType?.tags,
    'alias',
  ).map((category) => category.alias);

  const itemsByTags = data?.filter((item) =>
    item.tags?.some((tag) => workingFieldCategories.includes(tag)),
  );

  const groupedDataByJourneyType = itemsByTags?.map((item) => ({
    ...item,
    journeyType:
      currentJourneyType?.tags?.find((tag) => item.tags?.includes(tag.alias))?.names?.[locale] ??
      '',
  }));

  return { actionSheet, activeTab, isLoading, setActiveTab, groupedDataByJourneyType };
};

export default useSingleActionSheet;
