export type NameTrait = { [key: string]: string };
export type Categorie = { id: number; name: string; server: string };
export type JourneyType =
  | { id: number; name: NameTrait | undefined; alias: string; tags: Tag[]; filterTags: string[] }
  | undefined;
export type Media = {
  file: {
    contentUrl: string | null;
    description: string | null;
    filePath: string;
    id: number;
    originalName: string;
    path: string;
    server: string;
  };
  id: number;
  mimeType: string | null;
  originalName: string | null;
  server: string;
  webUrl: string | null;
  createdAt: Date;
  filePath: string;
};
export type WorkingFieldCategory = { id: number; name: NameTrait; server: string };
export type Dashboard = {
  displayName: boolean;
  id: number;
  journeyTypes: JourneyType;
  name: NameTrait;
  server: string;
};
export type Copil = {
  access: boolean;
  email: string | null;
  firstname: string | null;
  id: number;
  job: string | null;
  lastname: string | null;
  phone: string | null;
  server: string;
};
export type Manager = {
  configuration: Record<string, any>[] | null;
  id: number;
  locale: string;
  server: string;
  username: string;
};
export type Partner = { id: number; partner: ContractPartner; server: string; type: string };
export type ContractPartner = {
  id: number;
  server: string;
  expireAt: string | null;
  key: string;
  name: string;
};
export type Address = {
  address: string | undefined;
  city: string | undefined;
  coordinate: number[];
  country: string | undefined;
  postalCode: string | undefined;
  region: string | undefined;
};
export type Site = {
  name: string | null;
  capacity: number | null;
  address: Address;
  duplicate: boolean;
  addressInformationSheet: {
    address: Address;
    coordinate: number[];
    country: string | null;
    region: string | null;
    coordinates: Record<string, any>[] | null;
    id: number;
    server: string | null;
  };
};
export type DashboardItem = {
  colspan: number;
  Dashboard: Dashboard | null;
  displayName: boolean;
  graphType: GraphType;
  name: NameTrait;
  parameters: Record<string, any>[] | null;
  parent: string | null;
  server: string;
  text: string | null;
};
export type GraphType = {
  function: string | null;
  translation: { [key: string]: string };
  createdAt: Date;
  updatedAt: Date;
  name: string | null;
  parameters: Record<string, any>[];
  server: string;
};
export type ReportInstanceType = { id: number; name: string | null; server: string };
export type DashboardItemType = { id: number; name: string | null; server: string };
export type Referent = {
  lastname: string | null;
  firstname: string | null;
  job: string | null;
  civility: string | null;
  email: string | null;
  phone: string | null;
};
export type Tag = { names: NameTrait | undefined; alias: string };
export type DataPrivate = {
  primaryColor?: {
    name: string;
    value: string;
  };
  secondaryColor?: {
    name: string;
    value: string;
  };
};

export enum AppRoles {
  User = 'ROLE_USER',
  SuperAdmin = 'ROLE_SUPER_ADMIN',
  Admin = 'ROLE_ADMIN',
  AccountManager = 'ROLE_ACCOUNT_MANAGER',
  Manager = 'ROLE_MANAGER',
  Anonymous = 'IS_AUTHENTICATED_ANONYMOUSLY',
  TradDiagnostic = 'ROLE_TRAD_DIAGNOSTIC',
  TeamWever = 'ROLE_TEAM_WEVER',
  Provider = 'ROLE_PROVIDER',
}
