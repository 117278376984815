/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { AppBar, Toolbar } from '@mui/material';

import UserHeader from '@/components/layout/user-header/user-header';
import UserLanguages from '@/components/layout/user-languages/user-languages';

import { navbarStyle } from './navbar.style';

const AdminNavbar = () => {
  return (
    <div css={navbarStyle}>
      <AppBar className='navbar-app' component='nav'>
        <Toolbar className='toolbar-app'>
          <div className='space-div' />
          <div className='content'>
            <UserLanguages />
            <UserHeader />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AdminNavbar;
