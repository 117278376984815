/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import moment from 'moment';

import { CustomTable, Column } from '@/components/core/common/table/custom-table';
import { useCommunityContext } from '@/contexts/community-context';
import { _translate as t, _Security } from '@/services/core';
import { ressourcesTableStyle } from '@/views/ressources/components/ressources-table/ressources-table.style';
import Download from '@/views/ressources/components/download';
import DeleteMedia from '@/views/ressources/components/delete-media';
import { AppRoles } from '@/models/entities/common-types';
import { useUserContext } from '../../../../contexts/user-context';

export interface RowData {
  [key: string]: string | number | React.ReactNode | undefined;
  name: string;
  sharedBy?: string;
  date?: string;
  size?: string;
  download?: JSX.Element;
}

const RessourcesTable = () => {
  const { communityData } = useCommunityContext();
  const { user } = useUserContext();

  const managersCommunityIds = communityData?.managers.map(manager => manager.id) ?? [];

  const isAuthorizedToDelete = _Security.isGranted(AppRoles.Manager) && managersCommunityIds?.includes(user?.id as number);

  const columns: Column[] = [
    {
      id: 'name',
      label: t('manager_app_ressource_name'),
      minWidth: 170,
      icon: <FolderSharedIcon />,
      display: true
    },
    {
      id: 'sharedBy',
      label: t('manager_app_ressource_shared_by'),
      minWidth: 170,
      display: true
    },
    {
      id: 'date',
      label: t('manager_app_ressource_date'),
      minWidth: 170,
      align: 'right',
      display: true
    },
    {
      id: 'size',
      label: t('manager_app_ressource_size'),
      minWidth: 170,
      align: 'right',
      display: true
    },
    {
      id: 'download',
      label: '',
      minWidth: 20,
      align: 'right',
      display: true
    },
    {
      id: 'delete',
      label: '',
      minWidth: 20,
      align: 'right',
      display: isAuthorizedToDelete
    },
  ];

  const rows = communityData?.mediaPrivates.map((media) => ({
    name: media.originalName ?? '',
    sharedBy: '',
    date: media.createdAt ? moment(media.createdAt).locale('fr').format('L') : '',
    size: '',
    download: <Download media={media} />,
    delete: <DeleteMedia media={media} />
  }));

  return (
    <div css={ressourcesTableStyle}>
      <CustomTable<RowData> columns={columns.filter(column => column.display)} rows={rows?.reverse() ?? []} />
    </div>
  );
};

export default RessourcesTable;
