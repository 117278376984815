import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { RoutesPath } from '@/components/core/common/helpers';
import { AppRoles } from '@/models/entities/common-types';
import { UserInterface } from '@/models/entities/user-interface';
import {
  _Security as security,
  _Notifications as notifications,
  _translate as t,
  _Storage as storage,
} from '@/services/core';
import router from '@/services/core/router';

interface UserProps {
  children: ReactElement;
}

interface UserContextInterface {
  isLogged: boolean;
  userLoading: boolean;
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLogged: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserInterface | undefined;
}

export const UserContext = createContext<UserContextInterface | null>(null);

export const UserProvider = ({ children }: UserProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(security.isAuthenticated());
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [user, setUser] = useState<UserInterface | undefined>(undefined);

  const getResponse = useCallback(async () => {
    if (!isAuthenticated) return null;
    if (!security.isGranted(AppRoles.User)) {
      if (router.currentUri !== RoutesPath.Init) {
        notifications.error(t('manager_app_title_error'), t('manager_app_only_admin_access'));
      }
      return router.navigate(RoutesPath.Login, null);
    }
    setUserLoading(true);

    await security.init();
    setUser(storage.get('user'));

    setIsLogged(true);
    setUserLoading(false);
  }, [isAuthenticated]);

  useEffect(() => {
    getResponse();
  }, [getResponse]);

  return (
    <UserContext.Provider
      value={{
        isLogged,
        userLoading,
        isAuthenticated,
        setIsAuthenticated,
        setIsLogged,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext) as UserContextInterface;
