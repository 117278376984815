import { AxiosResponse } from 'axios';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { getNumbersFromString } from '@/components/core/common/helpers';
import { useUserCommunitiesContext } from '@/contexts/user-communities-context';
import { useUserContext } from '@/contexts/user-context';
import { DataPrivate, Media } from '@/models/entities/common-types';
import { CommunityInterface } from '@/models/entities/community-interface';
import { _Storage as storage } from '@/services/core';
import { _Api } from '@/services/core';

interface CommunityProviderProps {
  children: React.ReactElement;
}

interface CommunityContextInterface {
  community: string | undefined;
  setCommunity: React.Dispatch<React.SetStateAction<string | undefined>>;
  getCommunity: () => Promise<null | undefined>;
  setUserCommunity: (value: string) => void;
  communityData: CommunityInterface | undefined;
  setCommunityData: React.Dispatch<React.SetStateAction<CommunityInterface | undefined>>;
  communityId: string | number | undefined;
  communityLoading: boolean;
  setCommunityLoading: React.Dispatch<React.SetStateAction<boolean>>;
  dataPrivate: DataPrivate | undefined;
  communityLogo: Media['file'] | undefined;
}

export const CommunityContext = createContext<CommunityContextInterface | null>(null);

export const CommunityProvider = ({ children }: CommunityProviderProps) => {
  const { userCommunities } = useUserCommunitiesContext();
  const { isLogged } = useUserContext();

  const [community, setCommunity] = useState<string | undefined>(
    storage.get('Community') ?? undefined,
  );
  const [communityData, setCommunityData] = useState<CommunityInterface | undefined>();
  const [communityLoading, setCommunityLoading] = useState<boolean>(false);
  const [dataPrivate, setDataPrivate] = useState<DataPrivate | undefined>(undefined);
  const [communityLogo, setCommunityLogo] = useState<Media['file'] | undefined>(undefined);

  const communityId = community ? community.replace('/communities/', '') : userCommunities?.[0].id;

  const setUserCommunity = (value: string) => {
    setCommunity(value);
    storage.set('Community', value);
  };

  const getCommunity = useCallback(async () => {
    if (!isLogged) return null;
    if (!communityId) return null;
    setCommunityLoading(true);
    const response: AxiosResponse<CommunityInterface> = await _Api.get(
      `/communities/${communityId}`,
    );
    setCommunityData(response.data);
    if (response.data.dataPrivate && typeof response.data.dataPrivate === 'object') {
      setDataPrivate(response.data.dataPrivate);
    }

    setCommunityLogo(undefined);
    if (response.data.logo) {
      const logoResponse: AxiosResponse<Media['file']> = await _Api.get(
        `/medias/${getNumbersFromString(response.data.logo)}`,
      );
      setCommunityLogo(logoResponse.data);
    }
  }, [communityId, isLogged]);

  useEffect(() => {
    getCommunity();
  }, [getCommunity]);

  return (
    <CommunityContext.Provider
      value={{
        community,
        setCommunity,
        getCommunity,
        setUserCommunity,
        communityData,
        communityId,
        communityLoading,
        setCommunityLoading,
        setCommunityData,
        dataPrivate,
        communityLogo,
      }}
    >
      {children}
    </CommunityContext.Provider>
  );
};

export const useCommunityContext = () => useContext(CommunityContext) as CommunityContextInterface;
