import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { useMenuContext } from '@/contexts/menu-context';
import { GraphInterface } from '@/models/entities/graph-interface';
import { _Api } from '@/services/core';

interface DashboardDataProps {
  data: GraphInterface[] | undefined;
  totalItems: number | null;
}

const useGraphsByTags = () => {
  const [dashboardData, setDashboardData] = useState<DashboardDataProps>({
    data: undefined,
    totalItems: null,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentJourneyType } = useMenuContext();

  const { data, totalItems } = dashboardData;

  const getResponse = useCallback(async () => {
    const tagsFilter = currentJourneyType?.filterTags.filter(
      (tag) => tag !== undefined && tag !== null,
    );

    if (!tagsFilter) return null;

    if (tagsFilter && tagsFilter.length === 0) return null;

    setIsLoading(true);

    const response: AxiosResponse<GraphInterface[]> = await _Api.get(`managerapp/tags/dashboards`, {
      tags: JSON.stringify(tagsFilter),
    });

    if (response) {
      setDashboardData({
        data: response.data,
        totalItems: response.data.length,
      });
    }
    setIsLoading(false);
  }, [currentJourneyType]);

  useEffect(() => {
    getResponse();
  }, [getResponse]);

  return { data, totalItems, isLoading };
};

export default useGraphsByTags;
