import { Fragment } from 'react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { RoutesPath } from '@/components/core/common/helpers';
import { useMenuContext } from '@/contexts/menu-context';
import useGraphsByTags from '@/hooks/use-graphs-by-tags';
import { GraphInterface } from '@/models/entities/graph-interface';
import { _translate as t } from '@/services/core';
import Dashboard from '@/views/dashboard/dashboard';

const Graphs = () => {
  const { journeyTypeId, setJourneyTypeId } = useMenuContext();
  const { data, isLoading } = useGraphsByTags();

  const location = useLocation();

  const isMapView = location.pathname === `/${RoutesPath.Maps}` ? true : false;

  return (
    <Fragment>
      <Dashboard<GraphInterface>
        data={data}
        setJourneyTypeId={setJourneyTypeId}
        isLoading={isLoading}
        subtitle={!isMapView ? t('manager_app_see_graph') : t('manager_app_see_map')}
        path={!isMapView ? RoutesPath.Graphs : RoutesPath.Maps}
        journeyTypeId={journeyTypeId}
        title={!isMapView ? t('manager_app_graphs') : t('manager_app_maps')}
      />
    </Fragment>
  );
};

export default Graphs;
