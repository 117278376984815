import { AxiosResponse } from 'axios';
import { useState } from 'react';

import { useCommunityContext } from '@/contexts/community-context';
import { Media } from '@/models/entities/common-types';
import { _Api } from '@/services/core';

const useRessources = () => {
  const [activeTab, setActiveTab] = useState<number | undefined>(0);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const { communityData, getCommunity } = useCommunityContext();

  const uploadRessource = async (formData: FormData, filename: string) => {
    setUploadLoading(true);
    const response: string = await _Api.post(
      `media/private/community/${communityData?.id}`,
      formData,
      { filename },
      null,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
    if (response) {
      await getCommunity();
      setActiveTab(0);
    }
    setUploadLoading(false);
  };

  const downloadRessource = async (media: Media) => {
    setDownloadLoading(true);
    const response: AxiosResponse = await _Api.get(
      `media/private/community/${communityData?.id}/${media.id}`,
      null,
      {
        responseType: 'arraybuffer',
      },
    );
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: media.mimeType ?? 'application/zip' }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${media.originalName}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    setDownloadLoading(false);
  };

  const deleteRessource = async (media: Media) => {
    setUploadLoading(true);
    const response: AxiosResponse = await _Api.delete(
      `media/private/community/${communityData?.id}/${media.id}`,
      null,
      {
        responseType: 'arraybuffer',
      },
    );
    if (response) {
      await getCommunity();
    }
    setUploadLoading(false);
  };

  return {
    activeTab,
    setActiveTab,
    uploadRessource,
    uploadLoading,
    setUploadLoading,
    downloadRessource,
    downloadLoading,
    deleteRessource
  };
};

export default useRessources;
