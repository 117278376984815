/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Container, Tab } from '@mui/material';

import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import { RoutesPath } from '@/components/core/common/helpers';
import NavTabs from '@/components/core/common/nav-tabs/nav-tabs';
import TabPanel from '@/components/core/common/nav-tabs/tab-panel';
import LoadingLayout from '@/components/layout/loading-layout';
import { useCommunityContext } from '@/contexts/community-context';
import useStructureListByCommunity from '@/hooks/use-structures-by-community';
import { _translate as t } from '@/services/core';
import StructureTable from '@/views/structure-list/components/structures-table';
import useStructureList from '@/views/structure-list/use-structures-list';

import { structureListTabs } from './helpers';
import { structuresStyle } from './structure.style';

const StructureList = () => {
  const { activeTab, setActiveTab, status, setStatus } = useStructureList();

  const { data, isLoading, updateStatus } = useStructureListByCommunity();
  const { communityLoading } = useCommunityContext();

  const breadCrumbsItems = [
    { name: t('manager_app_structures_list'), route: RoutesPath.StructureList },
    { name: t(structureListTabs[activeTab ?? 0].title), route: undefined },
  ];

  const showTable = data?.some((structure) => structure.state === status);

  const currentTabPanel = structureListTabs.find((tab, index) => tab.status === status);

  const renderNavItems = structureListTabs.map(
    (tab, index) =>
      tab.status && <Tab key={index} label={t(tab.title)} onClick={() => setStatus(tab.status)} />,
  );

  if (communityLoading) {
    return <LoadingLayout />;
  }

  return (
    <div css={structuresStyle}>
      <Container className='structure-list-container' maxWidth='xl'>
        <Breadcrumbs items={breadCrumbsItems} />
        <div className='structure-content'>
          <NavTabs value={activeTab} setValue={setActiveTab}>
            {renderNavItems}
          </NavTabs>
          {isLoading ? (
            <LoadingLayout />
          ) : (
            <div className='structure-list-content'>
              <TabPanel value={activeTab} index={activeTab}>
                {showTable ? (
                  <StructureTable data={data} status={status} updateStatus={updateStatus} />
                ) : (
                  <div className='structure-error'>{t(currentTabPanel?.error)}</div>
                )}
              </TabPanel>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default StructureList;
