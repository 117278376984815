import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

import '@/assets/scss/main.scss';
import { RoutesPath } from '@/components/core/common/helpers';
import { CommunityProvider } from '@/contexts/community-context';
import { LanguageProvider } from '@/contexts/language-context';
import { MenuProvider } from '@/contexts/menu-context';
import { UserProvider } from '@/contexts/user-context';
import { Routes } from '@/routes';
import { _WeverCore as WeverCore, _Security as security } from '@/services/core';

import { UserCommunitesProvider } from './contexts/user-communities-context';
import router from './services/core/router';

const App: React.FC = () => {
  if (!security.isAuthenticated()) {
    router.navigate(RoutesPath.Login, null);
  }

  return (
    <WeverCore>
      <UserProvider>
        <UserCommunitesProvider>
          <LanguageProvider>
            <CommunityProvider>
              <MenuProvider>{router.render(Routes)}</MenuProvider>
            </CommunityProvider>
          </LanguageProvider>
        </UserCommunitesProvider>
      </UserProvider>
    </WeverCore>
  );
};
export default App;
