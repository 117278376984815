import { Column } from '@/components/core/common/table/custom-table';
import { _translate as t } from '@/services/core';

const ColumnBuilder = (isReferent: Boolean): Column[] => {
  const columns: Column[] = [
    {
      id: 'fullname',
      label: t('manager_app_member_fullname'),
      minWidth: 170,
      display: true
    },
    {
      id: 'email',
      label: t('manager_app_member_email'),
      minWidth: 170,
      align: 'center',
      display: true
    },
    {
      id: 'job',
      label: t('manager_app_member_job'),
      minWidth: 170,
      align: 'center',
      display: true
    },
  ];

  if (!isReferent) {
    columns.push({
      id: 'referent',
      label: t('manager_app_member_referent'),
      minWidth: 170,
      align: 'center',
      display: true
    });

    columns.push({
      id: 'actions',
      label: '',
      minWidth: 170,
      align: 'center',
      display: true
    });
  }

  return columns;
};

export default ColumnBuilder;
