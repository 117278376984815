/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import Loading from '@/components/common/loading';
import Collector from '@/components/core/collector';
import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import { useCommunityContext } from '@/contexts/community-context';
import { _translate as t, _Api, _Notifications } from '@/services/core';

type ConfigResponseType = {
  config?: number | null;
};

const MainInformation = () => {
  const [diagId, setDiagId] = useState<number | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const { communityData, communityLoading } = useCommunityContext();

  const breadCrumbsItems = [
    { name: t('manager_app_information'), route: undefined },
    { name: t('manager_app_main_information '), route: '' },
  ];

  const loadDiagnostic = async () => {
    if (!communityData) {
      return;
    }

    const response: AxiosResponse = await _Api.get(
      `/managerapp/config/${communityData.id}?key=information.diagnosticId`,
    );
    const configResponse: ConfigResponseType | null = response?.data;

    if (!configResponse || !configResponse.config) {
      return;
    }

    setDiagId(configResponse.config);
    setLoading(false);
  };

  useEffect(() => {
    if (!communityData) {
      return;
    }

    loadDiagnostic();
  }, [communityData]);

  return (
    <div>
      <Container className='ressources-container' maxWidth='xl'>
        <Breadcrumbs items={breadCrumbsItems} />
        <div className='ressources-content'>
          {loading || communityLoading || !communityData ? (
            <Loading />
          ) : (
            <>
              {typeof diagId !== 'number' ? (
                <div className='w-100 text-center'>{t('manager_app_no_data')}</div>
              ) : (
                <Collector
                  diagnostic={diagId}
                  objectId={communityData.id}
                  resource='community'
                  onSuccess={() =>
                    _Notifications.success(
                      t('manager_app_success'),
                      t('manager_app_information_saved'),
                    )
                  }
                />
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default MainInformation;
