import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useUserContext } from '@/contexts/user-context';
import { LanguageInterface } from '@/models/entities/language-interface';
import { _Api as Api } from '@/services/core';

interface LanguageProviderProps {
  children: ReactElement;
}

interface LanguageContextInterface {
  languages: LanguageInterface[] | undefined;
  setLanguages: React.Dispatch<React.SetStateAction<LanguageInterface[] | undefined>>;
  languageLoading: boolean;
}

export const LanguageContext = createContext<LanguageContextInterface | null>(null);

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [languages, setLanguages] = useState<LanguageInterface[] | undefined>();
  const [languageLoading, setLanguageLoading] = useState<boolean>(true);
  const { isLogged } = useUserContext();

  const getLanguages = useCallback(async () => {
    if (!isLogged) return null;
    setLanguageLoading(true);
    const response = await Api.get('/languages', { perPage: 1000 });
    setLanguages(response.data['hydra:member']);
    setLanguageLoading(false);
  }, [isLogged]);

  useEffect(() => {
    getLanguages();
  }, [getLanguages]);

  return (
    <LanguageContext.Provider
      value={{
        languages,
        languageLoading,
        setLanguages,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageContext) as LanguageContextInterface;
