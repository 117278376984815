import React from 'react';

import View from '@/components/view';
import { _UserProfile as UserProfile, _Security as security } from '@/services/core';
import { RoutesPath } from '@/components/core/common/helpers';
import { useCommunityContext } from '@/contexts/community-context';
import { useLanguageContext } from '@/contexts/language-context';
import { useMenuContext } from '@/contexts/menu-context';
import router from '@/services/core/router';
import { useUserContext } from '@/contexts/user-context';

const Profile = () => {
    const { setMenuConfiguration } = useMenuContext();
    const { setCommunityData, setCommunity } = useCommunityContext();
    const { setLanguages } = useLanguageContext();
    const { setIsAuthenticated, setIsLogged } = useUserContext();

    const logout = () => {
        security.deAuthenticate();
        setIsAuthenticated(false);
        setIsLogged(false);
        localStorage.clear();
        setLanguages(undefined);
        setCommunity(undefined);
        setCommunityData(undefined);
        setMenuConfiguration(undefined);
        router.navigate(RoutesPath.Login, null);
    }

    return (
        <View>
            <div className='col-12 mx-5 mt-md-5 pb-5 mb-5 pl-md-5' style={{ width: 'calc(100% - 6rem)' }}>
                <UserProfile ClickDisconnect={logout} />
            </div>
        </View>
    );
};

export default Profile;

