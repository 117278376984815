import { AxiosResponse } from 'axios';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import {
  containsNumbers,
  findParents,
  getTagByAlias,
  mapRecursive,
} from '@/components/core/common/helpers';
import { useCommunityContext } from '@/contexts/community-context';
import { JourneyType, Tag } from '@/models/entities/common-types';
import { MenuConfigurationInterface, MenuItemsInterface } from '@/models/entities/menu-interface';
import { _Api } from '@/services/core';
import router from '@/services/core/router';

interface MenuProviderProps {
  children: React.ReactElement;
}

interface MenuContextInterface {
  newMenu: MenuItemsInterface[] | undefined;
  menuConfiguration: { menu: MenuConfigurationInterface[] } | undefined;
  setMenuConfiguration: React.Dispatch<
    React.SetStateAction<
      | {
          menu: MenuConfigurationInterface[];
        }
      | undefined
    >
  >;
  journeyTypes: JourneyType[] | undefined;
  setJourneyTypes: React.Dispatch<React.SetStateAction<JourneyType[] | undefined>>;
  tags: Tag[] | undefined;
  journeyTypeId: number | undefined;
  setJourneyTypeId: React.Dispatch<React.SetStateAction<number | undefined>>;
  currentJourneyType: JourneyType;
  menuParentName: string[] | undefined;
  setMenuParentName: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  focusMenuItem: {
    id: string | number;
    title: string;
  };
  setFocusMenuItem: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      title: string;
    }>
  >;
  focusSubMenuItem: {
    id: string | number;
    title: string;
  };
  setFocusSubMenuItem: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      title: string;
    }>
  >;
}

export const MenuContext = createContext<MenuContextInterface | null>(null);

export const MenuProvider = ({ children }: MenuProviderProps) => {
  const [tags, setTags] = useState<Tag[] | undefined>(undefined);
  const [menuConfiguration, setMenuConfiguration] = useState<
    { menu: MenuConfigurationInterface[] } | undefined
  >(undefined);
  const [journeyTypes, setJourneyTypes] = useState<JourneyType[] | undefined>(undefined);
  const [menuParentName, setMenuParentName] = useState<string[] | undefined>(undefined);
  const [journeyTypeId, setJourneyTypeId] = useState<number | undefined>(0);
  const [newMenu, setNewMenu] = useState<MenuItemsInterface[] | undefined>(undefined);
  const [focusMenuItem, setFocusMenuItem] = useState<{ id: string | number; title: string }>({
    id: '',
    title: '',
  });
  const [focusSubMenuItem, setFocusSubMenuItem] = useState<{ id: string | number; title: string }>({
    id: '',
    title: '',
  });

  const { communityData, setCommunityLoading } = useCommunityContext();

  const getConfiguration = useCallback(async () => {
    setCommunityLoading(true);

    if (!communityData?.id) return null;

    const response: AxiosResponse<
      { menu: MenuConfigurationInterface[] } | undefined
    > = await _Api.get(`managerapp/menu/${communityData?.id}`);

    setMenuConfiguration(response.data);

    let items: unknown[] = [];

    response.data &&
      mapRecursive('menu', response.data?.menu, (menu) => {
        const newContent =
          menu.content && Object.entries(menu.content).flatMap(([key, value]) => [...value, key]);
        items.push(newContent, menu.tag);
        return menu;
      });

    const tagsConfig = [...new Set(items.flat(1).filter((item) => item !== undefined))] as string[];

    if (tagsConfig.length <= 0) return null;

    const tagResponse: AxiosResponse<Tag[]> = await _Api.get(`managerapp/tagContent`, {
      tagContent: JSON.stringify(tagsConfig),
    });

    setTags(tagResponse.data);

    const addIdsToMenu = response.data?.menu &&
      tagResponse.data && {
        menu: mapRecursive('menu', response.data?.menu, (menu) => ({
          ...menu,
          id: Math.floor(Math.random() * 100),
        })),
      };

    const newMenuItems =
      addIdsToMenu?.menu &&
      (mapRecursive('menu', addIdsToMenu.menu, (menu) => ({
        ...menu,
        id: menu.id,
        title: menu.title,
        url: menu.url,
        tag: menu.tag,
        icon: menu.icon,
        parentName: findParents<{ menu: MenuConfigurationInterface[] } | undefined>(
          addIdsToMenu,
          menu?.id,
          'title',
          'menu',
          'id',
        ),
        newContent:
          menu.content &&
          Object.entries(menu.content).map(([key, value], index) => ({
            id: index,
            name: getTagByAlias(key, tagResponse.data)?.names,
            alias: key,
            filterTags: [
              ...findParents<{ menu: MenuConfigurationInterface[] } | undefined>(
                addIdsToMenu,
                menu?.id,
                'tag',
                'menu',
                'id',
              ),
              key,
            ],
            tags: value.map((tag: string) => ({
              names: getTagByAlias(tag, tagResponse.data)?.names,
              alias: tag,
            })),
          })),
      })) as MenuItemsInterface[]);

    let baseContent: MenuItemsInterface[] = [];
    let contextPaths: string[] = [];

    newMenuItems &&
      mapRecursive('menu', newMenuItems, (menu) => {
        if (menu.newContent) {
          baseContent.push(menu);
          menu.url && contextPaths.push(`/${menu.url}`);
        }
        return menu;
      });

    setNewMenu(newMenuItems);

    setJourneyTypes(baseContent[0]?.newContent as JourneyType[]);
    setMenuParentName(baseContent[0].parentName);

    if (
      !response.data?.menu[0].isDynamicHomePage &&
      (contextPaths.includes(window.location.pathname) || window.location.pathname === '/')
    ) {
      baseContent[0]?.url && router.navigate(`${baseContent[0]?.url}?tab=0`, null);
    } else if (
      contextPaths.some((path) => window.location.pathname.includes(path)) &&
      containsNumbers(window.location.pathname)
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const currentBaseContent = baseContent.find(
        (content) => content.url && window.location.pathname.includes(content.url),
      );
      setJourneyTypes(currentBaseContent?.newContent);
      setMenuParentName(currentBaseContent?.parentName);
      const journeyTypeId = urlParams.get('tab') ? parseInt(urlParams.get('tab') as string) : 0;
      setJourneyTypeId(journeyTypeId);
    }

    setCommunityLoading(false);
  }, [communityData, setCommunityLoading]);

  const currentJourneyType = journeyTypes?.find((journey) => journey?.id === journeyTypeId);

  useEffect(() => {
    getConfiguration();
  }, [getConfiguration]);

  return (
    <MenuContext.Provider
      value={{
        newMenu,
        journeyTypes,
        setJourneyTypes,
        tags,
        journeyTypeId,
        setJourneyTypeId,
        menuConfiguration,
        currentJourneyType,
        menuParentName,
        setMenuParentName,
        focusMenuItem,
        setFocusMenuItem,
        focusSubMenuItem,
        setFocusSubMenuItem,
        setMenuConfiguration,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext) as MenuContextInterface;
