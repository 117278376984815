import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getUniqueListBy } from '@/components/core/common/helpers';
import { JourneyType, NameTrait } from '@/models/entities/common-types';
import { CommunityInterface } from '@/models/entities/community-interface';
import { GraphInterface } from '@/models/entities/graph-interface';
import { _Api } from '@/services/core';

const useSingleGraph = (
  data: GraphInterface[] | undefined,
  currentJourneyType: JourneyType,
  locale: string,
  communityData?: CommunityInterface,
) => {
  const [singleGraph, setSingleGraph] = useState<GraphInterface | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportId, setReportId] = useState<number | null>(null);

  const { id } = useParams<{ id: string }>();

  const getSingleGraph = useCallback(async () => {
    setIsLoading(true);

    const response: AxiosResponse<GraphInterface> = await _Api.get(
      `/dashboard_layouts/${id}/public`,
    );
    if (response) {
      setSingleGraph(response.data);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    const report = communityData?.report.replace('/reports/', '');
    setReportId(report ? parseInt(report) : null);
    getSingleGraph();
  }, [getSingleGraph, communityData]);

  const workingFieldCategories = getUniqueListBy<{ names: NameTrait | undefined; alias: string }>(
    currentJourneyType?.tags,
    'alias',
  ).map((category) => category.alias);

  const itemsByTags = data?.filter((item) =>
    item.tags?.some((tag) => workingFieldCategories.includes(tag)),
  );

  const groupedDataByJourneyType = itemsByTags
    ?.map((item) => ({
      ...item,
      journeyType:
        currentJourneyType?.tags?.find((tag) => item.tags?.includes(tag.alias))?.names?.[locale] ?? '',
    }))
    .sort((a, b) => {
      return a.journeyType.localeCompare(b.journeyType);
    });

  return { singleGraph, isLoading, reportId, groupedDataByJourneyType };
};

export default useSingleGraph;
