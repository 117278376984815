/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Breadcrumbs as CustomBreadcrumbs } from '@mui/material';

import {
  breadcrumbsItemStyle,
  breadcrumbsStyle,
} from '@/components/core/common/breadcrumbs/breadcrumbs.style';
import { useCommunityContext } from '@/contexts/community-context';
import router from '@/services/core/router';

import { RoutesPath } from '../helpers';

interface BreadcrumbsProps {
  items?: { name: string | JSX.Element | undefined; route?: string | undefined }[];
}

const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  const { dataPrivate } = useCommunityContext();

  const isLastElement = (index: number) =>
    items && items?.length > 1 && index === items?.length - 1;

  return (
    <div css={breadcrumbsStyle(dataPrivate)}>
      <div>
        <CustomBreadcrumbs separator='>' aria-label='breadcrumb' className='custom-breadcrumb'>
          {items?.map(
            (crumb, index: number) =>
              crumb && (
                <span
                  key={index}
                  onClick={() =>
                    !isLastElement(index) &&
                    crumb.route &&
                    router.navigate(
                      crumb.route !== RoutesPath.Init ? `/${crumb.route}` : RoutesPath.Init,
                      null,
                    )
                  }
                  css={breadcrumbsItemStyle(isLastElement(index), dataPrivate)}
                >
                  {crumb.name}
                </span>
              ),
          )}
        </CustomBreadcrumbs>
      </div>
    </div>
  );
};

export default Breadcrumbs;
