import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { useCommunityContext } from '@/contexts/community-context';
import { useMenuContext } from '@/contexts/menu-context';
import {
  ActionsSheetInterface,
  CommunityActionsSheetInterface,
} from '@/models/entities/actions-sheets-interface';
import { _Api } from '@/services/core';

interface sheetActionsDataProps {
  data: ActionsSheetInterface[] | undefined;
  totalItems: number | null;
}

const useActionsSheetsByTags = () => {
  const [sheetActionsData, setSheetActionsData] = useState<sheetActionsDataProps>({
    data: undefined,
    totalItems: null,
  });

  const { currentJourneyType } = useMenuContext();
  const { communityData } = useCommunityContext();

  const [isLoading, setIsLoading] = useState(false);

  const { data, totalItems } = sheetActionsData;

  const getActionsSheets = useCallback(async () => {
    const tagsFilter = currentJourneyType?.filterTags.filter(
      (tag) => tag !== undefined && tag !== null,
    );

    if (!tagsFilter) return null;

    if (tagsFilter && tagsFilter.length === 0) return null;

    setIsLoading(true);

    const response: AxiosResponse<ActionsSheetInterface[]> = await _Api.get(
      `managerapp/tags/sheetactions`,
      {
        tags: JSON.stringify(tagsFilter),
      },
    );

    const sheetActionsCommunity: AxiosResponse<
      CommunityActionsSheetInterface[]
    > = await _Api.get(`/communities/manager/sheet_actions`, { id: communityData?.id });

    const sheetsActionsIds = sheetActionsCommunity.data[0].sheetActions.map(({ sheetAction }) =>
      sheetAction.replace('/sheet_actions/', ''),
    );

    const sheetsActions = response.data.filter((item) =>
      sheetsActionsIds.includes(item.id.toString()),
    );

    if (response) {
      setSheetActionsData({
        data: sheetsActions,
        totalItems: response.data.length,
      });
    }
    setIsLoading(false);
  }, [currentJourneyType, communityData]);

  useEffect(() => {
    getActionsSheets();
  }, [getActionsSheets]);

  return { data, totalItems, isLoading };
};

export default useActionsSheetsByTags;
