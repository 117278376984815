import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CircularProgress, IconButton } from '@mui/material';
import React from 'react';

import { Media } from '@/models/entities/common-types';
import useRessources from '@/views/ressources/use-ressources';

interface DownloadProps {
  media: Media;
}

const Download = ({ media }: DownloadProps) => {
  const { downloadRessource, downloadLoading } = useRessources();

  return (
    <IconButton onClick={() => downloadRessource(media)}>
      {downloadLoading ? <CircularProgress size={25} /> : <FileDownloadOutlinedIcon />}
    </IconButton>
  );
};

export default Download;
