/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Container, Tab } from '@mui/material';
import React, { Fragment, useContext } from 'react';

import CustomAutocomplete from '@/components/core/common/autocomplete/custom-autocomplete';
import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import { RoutesPath } from '@/components/core/common/helpers';
import NavTabs from '@/components/core/common/nav-tabs/nav-tabs';
import translate from '@/components/core/common/translate';
import LoadingLayout from '@/components/layout/loading-layout';
import { useMenuContext } from '@/contexts/menu-context';
import useActionsSheetsByTags from '@/hooks/use-actions-sheets-by-tags';
import { ActionsSheetInterface } from '@/models/entities/actions-sheets-interface';
import { _translate as t, WeverCoreContext as Context } from '@/services/core';
import router from '@/services/core/router';
import { actionSheetStyle } from '@/views/action-sheets/single-action-sheet/action-sheet.style';
import DescriptionTab from '@/views/action-sheets/single-action-sheet/components/description/description-tab';
import DashboardTabs from '@/views/action-sheets/single-action-sheet/components/show-dashboard/dashboard-tab';
import { actionsSheetsTabs } from '@/views/action-sheets/single-action-sheet/helpers';
import useSingleActionSheet from '@/views/action-sheets/single-action-sheet/use-single-action-sheet';

const SheetAction = () => {
  const { currentJourneyType, menuConfiguration } = useMenuContext();
  const { data, isLoading: dataLoading } = useActionsSheetsByTags();
  const { locale } = useContext<Record<string, string>>(Context);
  const {
    actionSheet,
    activeTab,
    isLoading,
    setActiveTab,
    groupedDataByJourneyType,
  } = useSingleActionSheet(data, currentJourneyType, locale);

  const breadCrumbsItems = [
    {
      name: t(menuConfiguration?.menu[0].title),
      route: menuConfiguration?.menu[0].isDynamicHomePage ? RoutesPath.Init : undefined,
    },
    { name: t('manager_app_action_sheets'), route: RoutesPath.ActionSheets },
    {
      name: translate(currentJourneyType?.name),
      route: `${RoutesPath.ActionSheets}?tab=${currentJourneyType?.id}`,
    },
    { name: actionSheet?.name as string, route: undefined },
  ];

  const renderNavItems = actionsSheetsTabs(actionSheet).map(
    (tab, index) => tab.value && <Tab key={index} label={tab.title} />,
  );

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: ActionsSheetInterface | null,
  ) => {
    router.navigate(`${newValue?.id}`, null);
  };

  if (isLoading) return <LoadingLayout />;

  return (
    <div css={actionSheetStyle}>
      <div className='single-action-breadcrumbs'>
        <Breadcrumbs items={breadCrumbsItems} />
      </div>
      <div className='action-sheet-select'>
        <CustomAutocomplete<ActionsSheetInterface>
          handleChange={handleChange}
          data={groupedDataByJourneyType}
          defaultValue={actionSheet}
          getOptionLabel={(option) => option.name ?? ''}
          groupBy={(option) => (option.journeyType ? option.journeyType : null)}
          isLoading={dataLoading}
          loadingIconSize={15}
          renderOption={(props, option) => (
            <li style={{ fontSize: '16px' }} {...props}>
              {option.name}
            </li>
          )}
          size='small'
        />
      </div>
      <Container className='action-sheet' maxWidth='xl'>
        <NavTabs value={activeTab} setValue={setActiveTab}>
          {renderNavItems}
        </NavTabs>
        {actionSheet && (
          <Fragment>
            <DescriptionTab actionSheet={actionSheet} tabId={activeTab} />
            {actionSheet.evaluationDashboard && (
              <DashboardTabs
                tabId={activeTab as number}
                dashboardType='evaluation'
                actionSheetId={actionSheet.id}
                dashboard={actionSheet.evaluationDashboard}
                index={1}
              />
            )}

            {actionSheet.dimensionDashboard && (
              <DashboardTabs
                tabId={activeTab}
                dashboardType='operation'
                actionSheetId={actionSheet.id}
                dashboard={actionSheet.dimensionDashboard}
                index={2}
              />
            )}

            {actionSheet.feedbackDashboard && (
              <DashboardTabs
                tabId={activeTab}
                dashboardType='feedback'
                actionSheetId={actionSheet.id}
                dashboard={actionSheet.feedbackDashboard}
                index={3}
              />
            )}
          </Fragment>
        )}
      </Container>
    </div>
  );
};

export default SheetAction;
