import { css } from '@emotion/react';

import { DataPrivate } from '@/models/entities/common-types';

export const accordionSummaryStyle = css`
  .accordion-content {
    box-shadow: none;
    padding-bottom: 20px;
    .accordion-summary {
      min-height: 50px;
      box-shadow: 0px 0px 4px 0px #00000029;
      background-color: white;

      .header-content {
        display: flex;
        align-items: center;

        .circle-icon {
          font-size: 15px;
          font-family: Helvetica;
          font-weight: 700;
          color: #183d4c;
        }

        span {
          font-size: 28px;
          font-family: Helvetica;
          font-weight: 700;
          padding-left: 30px;
          color: #183d4c;
        }
      }

      .angle-icon {
        font-size: 28px;
        font-family: Helvetica;
        font-weight: 700;
        color: #183d4c;
      }
    }

    .Mui-expanded {
      background-color: #f2f2f2 !important;
    }
  }
`;

export const accordionDetailsStyle = (dataPrivate?: DataPrivate) => css`
  padding-top: 1px;
  .accordion-details {
    box-shadow: 0px 0px 4px 0px #00000029;
    display: flex;
    justify-content: space-between;
    padding: 8px 15px 8px 100px;

    .title {
      font-size: 24px;
      font-weight: 400;
      color: #343434;
      font-family: 'Questrial', sans-serif;
    }

    .icon-content {
      display: flex;
      align-items: center;
      cursor: pointer;
      .eye-icon {
        color: ${dataPrivate?.primaryColor?.value ?? '#d73755'};
        font-size: 18px;
      }

      .subtitle {
        font-size: 18px;
        font-weight: 400;
        color: ${dataPrivate?.primaryColor?.value ?? '#d73755'};
        font-family: 'Questrial', sans-serif;
        padding-left: 10px;
        text-decoration: underline;
        white-space: nowrap;
      }
    }
  }
`;

export const dashboardStyle = css`
  .dashboard-container {
    .content {
      padding-top: 50px;
    }
  }
`;
