import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { StructureValidationState } from '@/components/core/common/helpers';
import { useCommunityContext } from '@/contexts/community-context';
import { StructureReferencementInterface } from '@/models/entities/structure-interface';
import { _Api } from '@/services/core';

interface StructureListDataProps {
  data: StructureReferencementInterface[] | undefined;
  totalItems: number | null;
}

const useStructureListByCommunity = () => {
  const [structureListData, setStructureListData] = useState<StructureListDataProps>({
    data: undefined,
    totalItems: null,
  });

  const { communityData } = useCommunityContext();

  const [isLoading, setIsLoading] = useState(false);

  const { data, totalItems } = structureListData;

  const getStructureList = useCallback(async () => {
    if (!communityData?.id) return null;

    setIsLoading(true);

    const response: AxiosResponse<StructureReferencementInterface[]> = await _Api.get(
      `/structure_referencements/community/${communityData?.id}`,
    );

    if (response) {
      setStructureListData({
        data: response.data,
        totalItems: response.data?.length,
      });
    }
    setIsLoading(false);
  }, [communityData]);

  const updateStatus = async (structureId: number) => {
    setIsLoading(true);
    await _Api.get(
      `/structure_referencements/create/${structureId}/${communityData?.id}/${StructureValidationState.accepted}`,
    );
    getStructureList();
  };

  useEffect(() => {
    getStructureList();
  }, [getStructureList]);

  return { data, totalItems, isLoading, updateStatus };
};

export default useStructureListByCommunity;
