/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

import { RoutesPath } from '@/components/core/common/helpers';
import { headerStyle } from '@/components/layout/user-header/header.style';
import { useCommunityContext } from '@/contexts/community-context';
import { useLanguageContext } from '@/contexts/language-context';
import { useMenuContext } from '@/contexts/menu-context';
import { useUserContext } from '@/contexts/user-context';
import { _Security as security, _translate as t } from '@/services/core';
import router from '@/services/core/router';

const UserHeader = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setMenuConfiguration } = useMenuContext();
  const { setCommunityData, setCommunity } = useCommunityContext();
  const { setLanguages } = useLanguageContext();

  const { setIsAuthenticated, setIsLogged, user } = useUserContext();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuItemClick = (action: () => void) => () => {
    action();
    setAnchorEl(null);
  };

  const logout = () => {
    security.deAuthenticate();
    setIsAuthenticated(false);
    setIsLogged(false);
    localStorage.clear();
    setLanguages(undefined);
    setCommunity(undefined);
    setCommunityData(undefined);
    setMenuConfiguration(undefined);
    router.navigate(RoutesPath.Login, null);
    setAnchorEl(null);
  }



  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <div css={headerStyle}>
      <div aria-describedby={id} onClick={handleClick} className='title-content'>
        <AccountCircleOutlinedIcon fontSize='large' className='account-icon' />
        <div className='user-info'>
          <span>{t('manager_app_my_account')}</span>
          <p className='user-email'>{user?.username}</p>
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClick}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleMenuItemClick(() => router.navigate(RoutesPath.Profile, null))}>{t('userapp_account')}</MenuItem>
        <MenuItem onClick={handleMenuItemClick(logout)}><span>{t('logout')}</span></MenuItem>
      </Menu>
    </div>
  );
};

export default UserHeader;
