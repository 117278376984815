/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import Tabs from '@mui/material/Tabs';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useCommunityContext } from '@/contexts/community-context';

import { tabsStyle } from './tabs.style';

interface NavTabsProps {
  value?: number | undefined;
  setValue?: React.Dispatch<React.SetStateAction<number | undefined>>;
  children: React.ReactNode;
}

const NavTabs = ({ value, setValue, children }: NavTabsProps) => {
  const history = useHistory<History>();
  const location = useLocation<Location>();
  const { dataPrivate } = useCommunityContext();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue?.(newValue);
    const params = new URLSearchParams({
      tab: newValue?.toString() as string,
    }).toString();
    history.push({
      pathname: location.pathname,
      search: `?${params}`,
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get('tab')) {
      setValue?.(parseInt(urlParams.get('tab') as string));
    } else {
      const params = new URLSearchParams({
        tab: value?.toString() ?? '0',
      }).toString();
      history.push({
        pathname: location.pathname,
        search: `?${params}`,
      });
    }
  }, [setValue, history, value, location.search, location.pathname]);

  return (
    <div css={tabsStyle(dataPrivate)}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='basic tabs example'
        centered
        variant='fullWidth'
        className='custom-tabs'
      >
        {children}
      </Tabs>
    </div>
  );
};

export default NavTabs;
