import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

import logo from '@/assets/img/brand/logo.png';
import { RoutesPath } from '@/components/core/common/helpers';
import LoginForm from '@/components/core/security/login/login';
import MagicLoginForm from '@/components/core/security/login/magic-login';
import { useUserContext } from '@/contexts/user-context';
import { AppRoles } from '@/models/entities/common-types';
import { _Notifications, _Security, _translate as t } from '@/services/core';

const LoginView = () => {
  const [magicLogin, setMagicLogin] = useState<boolean>(false);
  const { setIsAuthenticated } = useUserContext();

  const history = useHistory<History>();
  return (
    <div className='login-container'>
      <div className='content-box'>
        <div className='content'>
          <img alt='logo' src={logo} />
          <div className='title'>
            <p>LA MOBILITE</p>
            <span>PARTICIPATIVE</span>
          </div>
        </div>
      </div>
      <div className='form-container'>
        <div className='form-content'>
          {!magicLogin ? (
            <p>{t('manager_app_connexion_title')}</p>
          ) : (
            <div className='magic-token-box'>
              <p className='magic-token-title'>{t('magic_token_title')}</p>
              <span className='magic-token-subtitle'>{t('magic_token_subtitle')}</span>
            </div>
          )}
          <div className='form'>
            <Row className='form-box'>
              <Col lg='7' md='7'>
                <Card className='bg-white border-0'>
                  <CardBody className='px-lg-5 py-lg-5'>
                    {!magicLogin ? (
                      <LoginForm
                        setMagicLogin={setMagicLogin}
                        onSuccess={() => {
                          if (!_Security.isGranted(AppRoles.Manager)) {
                            _Notifications.error(t('oops'), t('required_role_missing'));
                            return false;
                          }
                          setIsAuthenticated(true);
                          history.push(RoutesPath.Init);
                          return true;
                        }}
                      />
                    ) : (
                      <MagicLoginForm setMagicLogin={setMagicLogin} />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
