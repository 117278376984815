import { RoutesPath } from '@/components/core/common/helpers';
import Admin from '@/components/layout';
import { AppRoles } from '@/models/entities/common-types';
import { RouteInterface } from '@/models/entities/route-interface';
import { _translate as t } from '@/services/core';
import ActionSheets from '@/views/action-sheets/actions-sheets';
import SheetAction from '@/views/action-sheets/single-action-sheet/sheet-action';
import Graphs from '@/views/graphs/graphs';
import Graph from '@/views/graphs/single-graph/graph';
import HomePage from '@/views/homepage/home-page';
import MainInformation from '@/views/information/main/main-information';
import ProjectMembers from '@/views/information/project-members/project-members';
import LoginView from '@/views/login/login-view';
import Profile from '@/views/profile/profile';
import Ressources from '@/views/ressources/ressources';

import StructureList from './views/structure-list/structure-list';

export const Routes: RouteInterface[] = [
  {
    path: RoutesPath.Login,
    name: 'login',
    component: LoginView,
    routes: [],
  },
  {
    path: RoutesPath.Magic,
    name: 'magic-redirect',
    component: LoginView,
    routes: [],
  },
  {
    path: '',
    component: Admin,
    roles: [AppRoles.User],
    isBreadcrumbs: true,
    routes: [
      {
        path: RoutesPath.Init,
        name: 'home',
        exact: true,
        component: HomePage,
        title: 'home',
        breadcrumbName: t('manager_app_home'),
      },
      {
        path: RoutesPath.Maps,
        name: 'maps',
        exact: true,
        component: Graphs,
        title: 'maps-page',
        breadcrumbName: t('manager_app_maps'),
      },
      {
        path: `${RoutesPath.Maps}/:id`,
        name: 'action-sheet',
        exact: true,
        component: Graph,
        title: 'map-page',
        breadcrumbName: 'id',
      },
      {
        path: RoutesPath.Graphs,
        name: 'graphs',
        exact: true,
        component: Graphs,
        title: 'graphs-page',
        breadcrumbName: t('manager_app_graphs'),
      },
      {
        path: `${RoutesPath.Graphs}/:id`,
        name: 'action-sheet',
        exact: true,
        component: Graph,
        title: 'graph-page',
        breadcrumbName: 'id',
      },
      {
        path: RoutesPath.ActionSheets,
        name: 'action-sheets',
        exact: true,
        component: ActionSheets,
        title: 'action-sheets-page',
        breadcrumbName: t('manager_app_action_sheets'),
      },
      {
        path: `${RoutesPath.ActionSheets}/:id`,
        name: 'action-sheet',
        exact: true,
        component: SheetAction,
        title: 'action-sheet-page',
        breadcrumbName: 'id',
      },
      {
        path: RoutesPath.Resources,
        name: 'ressources',
        exact: true,
        component: Ressources,
        title: 'ressources',
        breadcrumbName: t('manager_app_ressources'),
      },
      {
        path: RoutesPath.ProjectMembers,
        name: 'information_project_members',
        exact: true,
        component: ProjectMembers,
        title: 'project-members',
        breadcrumbName: t('manager_app_info_project_members'),
      },
      {
        path: RoutesPath.Information,
        name: 'information',
        exact: true,
        component: MainInformation,
        title: 'information',
        breadcrumbName: t('manager_app_main_information'),
      },
      {
        path: RoutesPath.StructureList,
        name: 'structure-list',
        exact: true,
        component: StructureList,
        title: 'structure-list-page',
        breadcrumbName: t('manager_app_structures_list'),
      },
      {
        path: RoutesPath.Profile,
        name: 'profile',
        exact: true,
        component: Profile,
        title: 'profile',
        breadcrumbName: t('manager_app_profile'),
      },
    ],
  },
];
