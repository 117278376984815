/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { Fragment, useState } from 'react';

import { getTagByAlias } from '@/components/core/common/helpers';
import translate from '@/components/core/common/translate';
import { menuIcons } from '@/components/layout/user-menu/helpers';
import { useCommunityContext } from '@/contexts/community-context';
import { useMenuContext } from '@/contexts/menu-context';
import { MenuItemsInterface } from '@/models/entities/menu-interface';
import { _translate as t } from '@/services/core';
import router from '@/services/core/router';

import { userSidebarMenuStyle, userSidebarMenuSubContent } from './user-sider-menu.style';

interface UserSidebarMenuProps {
  sidebarOpen: boolean;
  setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserSidebarMenu = ({ sidebarOpen, setSideBarOpen }: UserSidebarMenuProps) => {
  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({ key: false });
  const { dataPrivate } = useCommunityContext();

  const {
    setJourneyTypes,
    newMenu,
    tags,
    setJourneyTypeId,
    setMenuParentName,
    focusMenuItem,
    setFocusMenuItem,
    setFocusSubMenuItem,
  } = useMenuContext();

  const handleUrl = (url: string) => {
    router.navigate(url, null);
    setJourneyTypeId(0);
  };

  const handleClick = (item: MenuItemsInterface, id: string | number, url: string | undefined) => {
    id !== 0 && setFocusMenuItem({ id, title: item.title });
    setFocusSubMenuItem({ id: '', title: '' });
    !url ? setIsOpen((prevState) => ({ ...prevState, [id]: !prevState[id] })) : handleUrl(url);
    !sidebarOpen && id === 0 && setSideBarOpen(true);
  };

  const handleSubMenuClick = (
    item: MenuItemsInterface,
    id: string | number,
    url: string | undefined,
  ) => {
    url && setFocusSubMenuItem({ id, title: item.title });
    setFocusMenuItem({ id: '', title: '' });
    !url ? setIsOpen((prevState) => ({ ...prevState, [id]: !prevState[id] })) : handleUrl(url);
    item.newContent && setJourneyTypes(item.newContent);
    url && setMenuParentName(item.parentName);
  };

  const getMenuIcon = (item: MenuItemsInterface | undefined) =>
    menuIcons.find((menu) => menu.title === item?.title);

  const isSelected = (item: MenuItemsInterface | undefined, index: number) =>
    focusMenuItem.title === item?.title;

  const renderSubMenuItems = (items: MenuItemsInterface[], parentIndex: number, paddingLeft = 35) =>
    items?.map((item, index) => (
      <Fragment key={index}>
        <div
          className='sub-content'
          css={userSidebarMenuSubContent(item, sidebarOpen)}
          key={index}
          style={{ paddingLeft }}
          onClick={() =>
            handleSubMenuClick(item, `${index}-${parentIndex}-${item.title}`, item.url)
          }
        >
          <ListItemText
            className='list-item-text'
            primary={item?.tag ? translate(getTagByAlias(item.tag, tags)?.names) : t(item?.title)}
          />
        </div>
        {item.menu && sidebarOpen && (
          <Collapse in={isOpen[`${index}-${parentIndex}-${item.title}`]}>
            {renderSubMenuItems(item.menu, index, paddingLeft + 20)}
          </Collapse>
        )}
      </Fragment>
    ));

  return (
    <Fragment>
      {newMenu &&
        newMenu?.map((item: MenuItemsInterface | undefined, index: number) => {
          let SvgComponent = getMenuIcon(item)?.icon as React.ElementType;
          return (
            <div
              key={index}
              css={userSidebarMenuStyle(
                item,
                sidebarOpen,
                isOpen[index] ?? isSelected(item, index),
                dataPrivate,
              )}
            >
              {index === 1 && <hr />}
              <div
                onClick={() => {
                  item && handleClick(item, index, item.url);
                }}
              >
                <ListItem className='list-item' key={index} disablePadding>
                  <ListItem className='list-button'>
                    {!getMenuIcon(item)?.displayIcon && sidebarOpen && (
                      <div className='rectangle-icon'></div>
                    )}
                    <ListItemIcon className='list-item-icon'>
                      {getMenuIcon(item)?.displayIcon && <SvgComponent className='svg-component' />}
                      {!getMenuIcon(item)?.displayIcon && !sidebarOpen && (
                        <SvgComponent className='svg-component' />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      className='list-item-text'
                      primary={
                        item?.tag ? translate(getTagByAlias(item.tag, tags)?.names) : t(item?.title)
                      }
                    />
                  </ListItem>
                </ListItem>
              </div>
              {item?.menu && sidebarOpen && (
                <Collapse in={isOpen[index]}>{renderSubMenuItems(item.menu, index)}</Collapse>
              )}
            </div>
          );
        })}
    </Fragment>
  );
};

export default UserSidebarMenu;
