import { AxiosResponse } from 'axios';
import { useState, useEffect, useCallback } from 'react';

import { useCommunityContext } from '@/contexts/community-context';
import CopilMemberInterface from '@/models/entities/copil-member-interface';
import { _Api, _Logger, _Notifications, _translate as t } from '@/services/core';

const useMembers = () => {
  const [activeTab, setActiveTab] = useState<number | undefined>(0);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [copilMembers, setCopilMembers] = useState<CopilMemberInterface[]>([]);

  const { communityData, communityLoading } = useCommunityContext();

  const deleteMember = async (index: number) => {
    if (!communityData) {
      return;
    }

    const copyMembers = [...copilMembers];
    const copyMembersOriginal = [...copilMembers];
    copyMembers.splice(index, 1);
    setCopilMembers(copyMembers);

    try {
      await _Api.put(`/communities/${communityData.id}/copil_members`, {
        ...communityData,
        copilMembers: copyMembers,
      });
    } catch (e) {
      setCopilMembers(copyMembersOriginal);
    }
  };

  const updateMember = async (member: CopilMemberInterface, index: number | null) => {
    if (!communityData) {
      return;
    }

    const initialCopilMembers = [...copilMembers];
    const copyMembers = [...copilMembers];

    // S'il existe on modifie
    if (typeof index === 'number') {
      copyMembers[index] = { ...copyMembers[index], ...member };
      setCopilMembers(copyMembers);
    } else {
      copyMembers.push(member);
    }

    try {
      await _Api.put(`/communities/${communityData.id}/copil_members`, {
        id: communityData.id,
        copilMembers: copyMembers,
      });

      if (typeof index !== 'number') {
        setCopilMembers(copyMembers);
      }
    } catch (e) {
      _Notifications.error(
        t('manager_app_api_error'),
        t('manager_app_api_error_save'),
        t('manager_app_body_error_save'),
      );
      _Logger.error('use-project-member', 'No update done');
      setCopilMembers(initialCopilMembers);
    }
  };

  const getMembers = useCallback(async () => {
    if (!communityData) {
      return;
    }

    try {
      const response: AxiosResponse = await _Api.get(
        `/communities/${communityData.id}/copil_members`,
      );
      const responseCopilMember: CopilMemberInterface[] | null = response?.data?.copilMembers;

      if (!responseCopilMember) {
        return;
      }

      setCopilMembers(responseCopilMember);
      setLoading(false);
    } catch (e: unknown) {
      _Logger.error('Copil Members', 'getMembers', { e });
      _Notifications.error(
        t('manager_app_api_error'),
        t('manager_app_api_get_members'),
        t('manager_app_body_error_save'),
      );
    }
  }, [communityData]);

  useEffect(() => {
    if (!communityData) {
      return;
    }

    getMembers();
  }, [communityData, getMembers]);

  return {
    activeTab,
    copilMembers,
    isLoading: communityLoading || isLoading,
    setActiveTab,
    updateMember,
    deleteMember,
  };
};

export default useMembers;
