import { useState } from 'react';

import { getUniqueListBy } from '@/components/core/common/helpers';
import { useMenuContext } from '@/contexts/menu-context';
import { NameTrait } from '@/models/entities/common-types';

const useDashboard = <T extends { tags: string[] | undefined }>(data?: T[]) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const { currentJourneyType } = useMenuContext();

  const workingFieldCategories = getUniqueListBy<{ names: NameTrait | undefined; alias: string }>(
    currentJourneyType?.tags,
    'alias',
  );

  const itemsByWorkingFieldCategory = (workingFieldCategory: string) =>
    data?.filter((item) => item.tags?.includes(workingFieldCategory));

  return {
    activeTab,
    setActiveTab,
    workingFieldCategories,
    itemsByWorkingFieldCategory,
  };
};

export default useDashboard;
