import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Col, FormGroup } from 'reactstrap';
import 'wever-dashboard/dist/styles/bootstrap.css';

import TabPanel from '@/components/core/common/nav-tabs/tab-panel';
import { useCommunityContext } from '@/contexts/community-context';
import { Dashboard as SheetActionDashboard } from '@/models/entities/common-types';
import { _Context as context, _Security as security, _translate as t } from '@/services/core';
import { _WeverDashboard as WeverDashboard } from '@/services/core';
import { _Api } from '@/services/core';

interface DashboardTabsProps {
  actionSheetId: number;
  dashboardType: string;
  tabId?: number;
  dashboard: SheetActionDashboard;
  index: number;
}

const DashboardTabs = ({
  actionSheetId,
  dashboardType,
  tabId,
  dashboard,
  index,
}: DashboardTabsProps) => {
  const { communityId } = useCommunityContext();
  const [dashboardId, setDashboardId] = useState<number>();
  const [reportId, setReportId] = useState<number>();
  const [loaded, setLoaded] = useState<boolean>();

  const getCommunityDashboards = useCallback(async () => {
    if (!actionSheetId || !communityId || !dashboardType || !dashboard) return null;
    const response: AxiosResponse<{ id: number; report: number }> = await _Api.get(
      `/communities/manager/sheet_action/dashboard?id=${communityId}&action=${actionSheetId}&type=${dashboardType}`,
    );

    setDashboardId(response.data.id);
    setReportId(response.data.report);
    setLoaded(true);
    return response;
  }, [communityId, actionSheetId, dashboardType, dashboard]);

  useEffect(() => {
    getCommunityDashboards();
  }, [getCommunityDashboards]);

  return (
    <TabPanel value={tabId} index={index}>
      {dashboardId &&
      reportId &&
      communityId &&
      security.token &&
      loaded &&
      context.locale &&
      dashboard ? (
        <div>
          <FormGroup row>
            <Col>
              <WeverDashboard
                editionMode
                dashboard={dashboardId}
                locale={context.locale}
                token={security.token}
                report={reportId}
                community={communityId}
              />
            </Col>
          </FormGroup>
        </div>
      ) : (
        <div className='w-100 text-center' style={{ color: '#ccc' }}>
          {t('adminapp_oopps_something_crashed')}
        </div>
      )}
    </TabPanel>
  );
};

export default DashboardTabs;
