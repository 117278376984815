/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useContext, useState } from 'react';

import { useLanguageContext } from '@/contexts/language-context';
import { WeverCoreContext as Context, _Translator as translator } from '@/services/core';

import { languageSelectStyle } from './language.style';

const UserLanguages = () => {
  const { languages } = useLanguageContext();
  const [value, setValue] = useState<string | undefined>(undefined);
  const { update } = useContext<Record<string, (arg: { locale: string }) => void>>(Context);

  const updateLocaleContext = (value: string) => {
    update({ locale: value });
  };

  const setTranslatorLocale = async (locale: string) => {
    await translator.setLocale(locale);
    updateLocaleContext(locale);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    setTranslatorLocale(event.target.value);
  };

  const defaultLanguage = languages?.find(({ locale }) => locale === 'fr');

  return (
    <div css={languageSelectStyle}>
      <Select
        className='language-select'
        labelId='app-simple-select-label'
        id='simple-select'
        value={value}
        onChange={handleChange}
        defaultValue={defaultLanguage?.locale}
        renderValue={(value) => <span>{value.toUpperCase()}</span>}
        IconComponent={(props) => <ExpandMoreIcon className='pop-icon' {...props} />}
      >
        {languages?.map((language) => (
          <MenuItem value={language.locale}>{language.name}</MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default UserLanguages;
