import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useUserContext } from '@/contexts/user-context';
import { CommunityInterface } from '@/models/entities/community-interface';
import { _Api } from '@/services/core';

interface UserCommunitesProps {
  children: ReactElement;
}

interface UserCommunitesContextInterface {
  userCommunities: CommunityInterface[] | undefined;
  userCommunitiesLoading: boolean;
  getResponse: () => Promise<null | undefined>;
}

export const UserCommunitesContext = createContext<UserCommunitesContextInterface | null>(null);

export const UserCommunitesProvider = ({ children }: UserCommunitesProps) => {
  const { isLogged } = useUserContext();
  const [userCommunities, setUserCommunities] = useState<CommunityInterface[] | undefined>();
  const [userCommunitiesLoading, setUserCommunitiesLoading] = useState<boolean>(true);

  const getResponse = useCallback(async () => {
    if (!isLogged) return null;
    setUserCommunitiesLoading(true);
    const response = await _Api.get(`/communities/me?all=true`);

    if (response) {
      setUserCommunities(response.data['hydra:member']);
    }
    setUserCommunitiesLoading(false);
  }, [isLogged]);

  useEffect(() => {
    getResponse();
  }, [getResponse]);

  return (
    <UserCommunitesContext.Provider
      value={{
        userCommunities,
        userCommunitiesLoading,
        getResponse,
      }}
    >
      {children}
    </UserCommunitesContext.Provider>
  );
};

export const useUserCommunitiesContext = () =>
  useContext(UserCommunitesContext) as UserCommunitesContextInterface;
