/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Stack,
  Tab,
} from '@mui/material';

import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import { RoutesPath } from '@/components/core/common/helpers';
import NavTabs from '@/components/core/common/nav-tabs/nav-tabs';
import TabPanel from '@/components/core/common/nav-tabs/tab-panel';
import translate from '@/components/core/common/translate';
import LoadingLayout from '@/components/layout/loading-layout';
import { useMenuContext } from '@/contexts/menu-context';
import { NameTrait } from '@/models/entities/common-types';
import { _Icon as Icon, _translate as t } from '@/services/core';
import router from '@/services/core/router';
import {
  accordionDetailsStyle,
  accordionSummaryStyle,
  dashboardStyle,
} from '@/views/dashboard/dashboard.style';
import useDynamicHomepage from '@/views/homepage/components/dynamic-homepage/use-dynamic-homepage';

const DynamicHomepage = () => {
  const { homePageData, isLoading } = useDynamicHomepage();
  const { journeyTypes, journeyTypeId, menuConfiguration } = useMenuContext();

  const breadCrumbsItems = [
    {
      name: t(menuConfiguration?.menu[0].title),
      route: menuConfiguration?.menu[0].isDynamicHomePage ? RoutesPath.Init : undefined,
    },
  ];

  const renderItems = (
    data: { name: string; path: string; id: number; subtitle: string; categoryName: string }[],
  ) =>
    data?.map((item, index: number) => (
      <div key={index} css={accordionDetailsStyle}>
        <AccordionDetails className='accordion-details'>
          <Stack direction='row' spacing={1} alignItems='center'>
            <span className='title'>{item.categoryName}</span>
            <span>-</span>
            <span className='title'>
              {typeof item.name === 'object' ? translate(item.name) : t(item.name)}
            </span>
          </Stack>
          <div
            className='icon-content'
            onClick={() => router.navigate(`${item.path}/${item.id}`, null)}
          >
            <VisibilityIcon className='eye-icon' fontSize='small' />
            <span className='subtitle'>{item.subtitle}</span>
          </div>
        </AccordionDetails>
      </div>
    ));

  const renderSingleItem = (data: { name: NameTrait; path: string; id: number }) => (
    <div css={accordionDetailsStyle}>
      <AccordionDetails className='accordion-details'>
        <span className='title'>
          {typeof data.name === 'object' ? translate(data.name) : t(data.name)}
        </span>
        <div className='icon-content' onClick={() => router.navigate(`${data.path}`, null)}>
          <VisibilityIcon className='eye-icon' fontSize='small' />
          <span className='subtitle'>{t('manager_app_see_graph')}</span>
        </div>
      </AccordionDetails>
    </div>
  );

  const renderCollapse = () =>
    homePageData?.map((pageData: any, index: number) => (
      <div key={index} css={accordionSummaryStyle}>
        <Accordion className='accordion-content' disableGutters>
          <AccordionSummary
            className='accordion-summary'
            expandIcon={<Icon name={faAngleDown} className='angle-icon' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <div className='header-content'>
              <FiberManualRecordIcon fontSize='small' className='circle-icon' />
              <span>{t(pageData.title)}</span>
            </div>
          </AccordionSummary>
          {pageData.type === 'multiData'
            ? renderItems(pageData.data)
            : renderSingleItem(pageData.data)}
        </Accordion>
      </div>
    ));

  const renderNavItems = journeyTypes?.map((journeyType, index) => (
    <Tab key={index} label={translate(journeyType?.name)} />
  ));

  return (
    <div css={dashboardStyle}>
      <Container className='dashboard-container' maxWidth='xl'>
        <Breadcrumbs items={breadCrumbsItems} />
        <div className='content'>
          <NavTabs value={journeyTypeId}>{renderNavItems}</NavTabs>
          {isLoading ? (
            <LoadingLayout />
          ) : (
            <TabPanel value={journeyTypeId} index={journeyTypeId}>
              <Stack fontSize='18px' fontWeight='bold' paddingTop='10px'>
                {t('manager_app_welcome_od')}
              </Stack>
              <Stack fontSize='18px' paddingTop='15px' paddingBottom='70px'>
                {t('manager_app_choose_category')}
              </Stack>
              {renderCollapse()}
            </TabPanel>
          )}
        </div>
      </Container>
    </div>
  );
};

export default DynamicHomepage;
