import { AxiosResponse } from 'axios';
import { useState, useCallback, useEffect, useContext } from 'react';

import {
  containsNumbers,
  getNumbersFromString,
  mapRecursive,
  RoutesPath,
} from '@/components/core/common/helpers';
import { useCommunityContext } from '@/contexts/community-context';
import { useMenuContext } from '@/contexts/menu-context';
import {
  ActionsSheetInterface,
  CommunityActionsSheetInterface,
} from '@/models/entities/actions-sheets-interface';
import { GraphInterface } from '@/models/entities/graph-interface';
import { MenuItemsInterface } from '@/models/entities/menu-interface';
import { _Api, WeverCoreContext as Context, _translate as t } from '@/services/core';

interface HomePageDataInterface {
  title: string;
  data: (GraphInterface | ActionsSheetInterface)[] | GraphInterface;
  type: string;
}

const useDynamicHomepage = () => {
  const [homePageData, setHomePageData] = useState<HomePageDataInterface[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { locale } = useContext<Record<string, string>>(Context);

  const { newMenu, menuConfiguration } = useMenuContext();
  const { communityData } = useCommunityContext();
  const { currentJourneyType } = useMenuContext();

  const getDashboards = useCallback(async (tagsFilter: string[]) => {
    if (!tagsFilter) return null;
    if (tagsFilter && tagsFilter.length === 0) return null;

    const response: AxiosResponse<GraphInterface[]> = await _Api.get(`managerapp/tags/dashboards`, {
      tags: JSON.stringify(tagsFilter),
    });

    if (!response.data) return null;

    return response.data;
  }, []);

  const getActionsSheets = useCallback(
    async (tagsFilter: string[]) => {
      if (!tagsFilter) return null;

      if (tagsFilter && tagsFilter.length === 0) return null;

      const response: AxiosResponse<ActionsSheetInterface[]> = await _Api.get(
        `managerapp/tags/sheetactions`,
        {
          tags: JSON.stringify(tagsFilter),
        },
      );

      const sheetActionsCommunity: AxiosResponse<
        CommunityActionsSheetInterface[]
      > = await _Api.get(`/communities/manager/sheet_actions`, { id: communityData?.id });

      const sheetsActionsIds = sheetActionsCommunity.data[0].sheetActions.map(({ sheetAction }) =>
        sheetAction.replace('/sheet_actions/', ''),
      );

      const sheetsActions = response.data.filter((item) =>
        sheetsActionsIds.includes(item.id.toString()),
      );

      if (!sheetsActions) return null;

      return sheetsActions;
    },
    [communityData],
  );

  const getPaths = (url: string) => {
    switch (url) {
      case 'maps':
        return RoutesPath.Maps;
      case 'graphs':
        return RoutesPath.Graphs;
      case 'action-sheets':
        return RoutesPath.ActionSheets;
      default:
        return '';
    }
  };

  const getSubtitle = (url: string) => {
    switch (url) {
      case 'maps':
        return t('manager_app_see_map');
      case 'graphs':
        return t('manager_app_see_graph');
      case 'action-sheets':
        return t('manager_app_see_action_sheet');
      default:
        return '';
    }
  };

  const getHomePageItems = useCallback(async () => {
    if (!menuConfiguration?.menu[0].isDynamicHomePage) return null;
    setIsLoading(true);
    let homePageItems: MenuItemsInterface[] = [];

    newMenu &&
      mapRecursive('menu', newMenu, (menu) => {
        if (menu.newContent || containsNumbers(menu.url ?? '')) {
          homePageItems.push(menu);
        }
        return menu;
      });
    const newHomePageItems = await Promise.all(
      homePageItems.map(async (pageItem) => {
        if (containsNumbers(pageItem.url ?? '')) {
          const response: AxiosResponse<GraphInterface> = await _Api.get(
            `/dashboard_layouts/${getNumbersFromString(pageItem.url ?? '')}/public`,
          );
          const itemData = { ...response.data, path: pageItem.url };
          return {
            title: pageItem.title,
            data: itemData,
            type: 'singleData',
          };
        } else {
          const newContent = pageItem.newContent?.find(
            (content) => content?.alias === currentJourneyType?.alias,
          );
          const tagsFilter = newContent?.filterTags.filter(
            (tag) => tag !== undefined && tag !== null,
          );
          const itemData =
            pageItem.url === 'action-sheets'
              ? await getActionsSheets(tagsFilter ?? [])
              : await getDashboards(tagsFilter ?? []);
          const tags = newContent?.tags;
          const tagsAlias = tags?.map((tag) => tag.alias);
          const data = (itemData as (GraphInterface | ActionsSheetInterface)[])?.filter((data) =>
            data.tags?.some((tag: string) => tagsAlias?.includes(tag)),
          );
          const dataWithCategory = data?.map((item) => ({
            ...item,
            categoryName: tags?.find((tag) => item.tags?.includes(tag.alias))?.names?.[locale],
            path: getPaths(pageItem.url ?? ''),
            subtitle: getSubtitle(pageItem.url ?? ''),
          }));
          return {
            title: pageItem.title,
            data: dataWithCategory,
            type: 'multiData',
          };
        }
      }),
    );
    setHomePageData(newHomePageItems);
    setIsLoading(false);
  }, [
    currentJourneyType,
    getActionsSheets,
    getDashboards,
    newMenu,
    locale,
    menuConfiguration?.menu,
  ]);

  useEffect(() => {
    getHomePageItems();
  }, [getHomePageItems]);

  return { homePageData, isLoading };
};

export default useDynamicHomepage;
