/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Accordion, AccordionDetails, AccordionSummary, Container, Tab } from '@mui/material';

import Breadcrumbs from '@/components/core/common/breadcrumbs/breadcrumbs';
import { RoutesPath } from '@/components/core/common/helpers';
import NavTabs from '@/components/core/common/nav-tabs/nav-tabs';
import TabPanel from '@/components/core/common/nav-tabs/tab-panel';
import translate from '@/components/core/common/translate';
import LoadingLayout from '@/components/layout/loading-layout';
import { useCommunityContext } from '@/contexts/community-context';
import { useMenuContext } from '@/contexts/menu-context';
import { NameTrait } from '@/models/entities/common-types';
import { _Icon as Icon, _translate as t } from '@/services/core';
import router from '@/services/core/router';
import useDashboard from '@/views/dashboard/use-dashboard';

import { accordionSummaryStyle, accordionDetailsStyle, dashboardStyle } from './dashboard.style';

interface DashboardProps<T> {
  data?: Array<T & { tags: string[] | undefined; name: string | null | NameTrait; id: number }>;
  subtitle: string;
  title: string;
  setJourneyTypeId: React.Dispatch<React.SetStateAction<number | undefined>>;
  isLoading: boolean;
  path: string;
  journeyTypeId?: number;
}

const Dashboard = <T extends Record<string, unknown>>({
  data,
  subtitle,
  setJourneyTypeId,
  isLoading,
  path,
  journeyTypeId,
  title,
}: DashboardProps<T>) => {
  const { communityLoading, dataPrivate } = useCommunityContext();
  const { journeyTypes, menuParentName, currentJourneyType, menuConfiguration } = useMenuContext();

  const { itemsByWorkingFieldCategory, workingFieldCategories } = useDashboard(data);

  const breadcrumbsMenu =
    menuParentName?.map((menu) => ({
      name: t(menu),
      route: menuConfiguration?.menu[0].isDynamicHomePage ? RoutesPath.Init : undefined,
    })) ?? [];

  const breadCrumbsItems = [
    ...breadcrumbsMenu,
    {
      name: translate(currentJourneyType?.name),
    },
  ];

  const renderItems = (workingFieldCategory: string) =>
    itemsByWorkingFieldCategory(workingFieldCategory)?.map((item, index: number) => (
      <div key={index} css={accordionDetailsStyle(dataPrivate)}>
        <AccordionDetails className='accordion-details'>
          <span className='title'>
            {typeof item.name === 'object' ? translate(item.name) : t(item.name)}
          </span>
          <div
            className='icon-content'
            onClick={() => router.navigate(`${path}/${item.id}?tab=${journeyTypeId}`, null)}
          >
            <VisibilityIcon className='eye-icon' fontSize='small' />
            <span className='subtitle'>{subtitle}</span>
          </div>
        </AccordionDetails>
      </div>
    ));

  const renderCollapse = (tabIndex: number | undefined) =>
    workingFieldCategories?.map((category, index: number) => (
      <div key={index} css={accordionSummaryStyle}>
        <Accordion className='accordion-content' disableGutters>
          <AccordionSummary
            className='accordion-summary'
            expandIcon={<Icon name={faAngleDown} className='angle-icon' />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <div className='header-content'>
              <FiberManualRecordIcon fontSize='small' className='circle-icon' />
              <span>{translate(category.names)}</span>
            </div>
          </AccordionSummary>
          {renderItems(category.alias)}
        </Accordion>
      </div>
    ));

  const renderNavItems = journeyTypes?.map((journeyType, index) => (
    <Tab key={index} label={translate(journeyType?.name)} />
  ));

  if (communityLoading) {
    return <LoadingLayout />;
  }

  return (
    <div css={dashboardStyle}>
      <Container className='dashboard-container' maxWidth='xl'>
        <Breadcrumbs items={breadCrumbsItems} />
        <div className='content'>
          <NavTabs value={journeyTypeId} setValue={setJourneyTypeId}>
            {renderNavItems}
          </NavTabs>
          {isLoading ? (
            <LoadingLayout />
          ) : (
            <TabPanel value={journeyTypeId} index={journeyTypeId}>
              {renderCollapse(journeyTypeId)}
            </TabPanel>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
